import '@styles/app.pcss';
import header from '@commons/header';
import main from '@commons/main';

export default function init() {
  // Commons
  header();
  main();
}

init();
